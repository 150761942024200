import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)
import axios from 'axios'
import jmespath from 'jmespath'
import _ from 'loadsh';

import jsonFile from '../../public/config/global-config.json'

export default new Vuex.Store({
  state: {
    TOKEN: "",
    CONFIG: jsonFile,
    DOCUMENTS: [],
    FILTER: {},
    SORT: {
      "columns": ["date"],
      "desc": [true]
    },
    SEARCH: "",
    LOADING: false
  },
  mutations: {
    setToken: (state, token) => (state.TOKEN = token),
    setTotalItems: (state, total_items) => (state.CONFIG.pagination.TOTAL_ITEMS = total_items),
    setConfig: (state, config) => (state.CONFIG = config),
    setLoading: state => {
      state.LOADING = !state.LOADING
    }, 

    setDocuments: (state, documents) => (state.DOCUMENTS = documents),
    setCurrentPage (state, current_page) {
      state.CONFIG.pagination.CURRENT_PAGE = current_page;
      this.dispatch('fetchDocuments');
    },
    setItemsPerPage (state, items_per_page) {
      state.CONFIG.pagination.ITEMS_PER_PAGE = items_per_page;
      this.dispatch('fetchDocuments');
    },
    setFilter (state, filter) {
      state.FILTER = filter;
      this.dispatch('fetchDocuments');
    },
    setSort (state, sorting) {
      state.SORT.columns = sorting.columns;
      state.SORT.desc = sorting.desc;
      this.dispatch('fetchDocuments');
    },
    setSearch (state, search) {
      state.SEARCH = search;
      this.dispatch('fetchDocuments');
    }
   },
  actions: {
    async fetchOptions({state, getters}){

      var f = []
      Object.values(state.CONFIG.field_mappings).forEach(e => {
        if (Object.keys(e.mapping).length && e.filter.length){
          f.push(e.field_name)
        }
      })
      var q = state.CONFIG.base_url;
      q += "?fields="+f.join(",");
      q += "&limit=-1"

      axios.get(q)
      .then(response => {
        var data = response.data.data;
        var o = {};
        data.forEach(doc =>{
          for (var [field, val] of Object.entries(doc)) {
            if (getters.getConfig.field_mappings[field] != undefined && !(Object.keys(getters.getConfig.field_mappings[field].mapping).length === 0)) {  
              if (!(field in o))
                o[field] = [];
              var mapping = getters.getConfig.field_mappings[field].mapping;
              var extracted = null
              // if (mapping.path === null && val !== null) {
              //   extracted = [{key: "", value: val[mapping.value]}];
              // }
              if (mapping.path !== null && mapping.value !== null &&val !== null) {
                extracted = jmespath.search(val, mapping.path).map((e) => {
                  return {
                    key: e[mapping.key],
                    value: e[mapping.value]
                  }
                });
              }

              if (extracted) {
                o[field] = [...o[field], ...extracted];
              }
            }       
          } 
        })
        for (var [field, val] of Object.entries(o)) {
          o[field] = _.orderBy(_.uniq(val, v => JSON.stringify(v)), ['value'],['asc'])
          getters.getConfig.field_mappings[field].options = o[field]
        }
      });
    },
    
    async fetchDocuments({ commit, getters }) {  
      commit("setLoading")
      axios.get(getters.getQueryString())
      .then(response => {
        var data = response.data.data;
        data.forEach((doc, doc_index) => {
          for (var [field, val] of Object.entries(doc)) {
            if (getters.getConfig.field_mappings[field] != undefined && !(Object.keys(getters.getConfig.field_mappings[field].mapping).length === 0) && getters.getConfig.field_mappings[field].mapping.constructor === Object) {  
              var mapping = getters.getConfig.field_mappings[field].mapping;
              
              if (!mapping.key && !mapping.value) {
                data[doc_index][field] = jmespath.search(val, mapping.path); 
              }
              if (!mapping.key && mapping.value) {
                data[doc_index][field] = jmespath.search(val, mapping.path +"."+ mapping.value); 
              } 
              if (mapping.key && mapping.value)  {
                data[doc_index][field] = jmespath.search(val, mapping.path).map((e) => {
                  return {
                    key: e[mapping.key],
                    value: e[mapping.value]
                  }
                });
              }
            }       
          }       
        });
        commit("setDocuments", data)
        commit("setTotalItems", response.data.meta.filter_count)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        commit("setLoading")
      })
    },
    // https://gerade.smc.page/items/corona_publiste/:id

    async getPaperByID({ getters }, id) { 
      return axios.get(getters.getQueryString(id, false, false, false, false ))
      .then(response => {
        var doc = response.data.data;

        for (var [field, val] of Object.entries(doc)) {
          if (getters.getConfig.field_mappings[field] != undefined && !(Object.keys(getters.getConfig.field_mappings[field].mapping).length === 0) && getters.getConfig.field_mappings[field].mapping.constructor === Object) {  
              var mapping = getters.getConfig.field_mappings[field].mapping;
              
              if (!mapping.key && !mapping.value) {
                doc[field] = jmespath.search(val, mapping.path); 
              }
              if (!mapping.key && mapping.value) {
                doc[field] = jmespath.search(val, mapping.path +"."+ mapping.value); 
              } 
              if (mapping.key && mapping.value)  {
                doc[field] = jmespath.search(val, mapping.path).map((e) => {
                  return {
                    key: e[mapping.key],
                    value: e[mapping.value]
                  }
                });
              }
          } 
        }
        return response.data.data;  
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
      })
    }
  },
  modules: {  
  },
  getters:{
    getAppName: (state) => state.CONFIG.publist_name,
    getRssUrl: (state) => state.CONFIG.rss_url,
    getFeedbackMail: (state) => state.CONFIG.feedback_mail,
    getAllDocuments: (state) => state.DOCUMENTS,
    getItemsPerPage: (state) => state.CONFIG.pagination.ITEMS_PER_PAGE,
    getTotalItems: (state) => state.CONFIG.pagination.TOTAL_ITEMS,
    getConfig: (state) => state.CONFIG,
    getPagination (state) {
      return {
        page: state.CONFIG.pagination.CURRENT_PAGE,
        itemsPerPage: state.CONFIG.pagination.ITEMS_PER_PAGE,
        totalItems: state.CONFIG.pagination.TOTAL_ITEMS,
        sortBy: state.SORT.columns,
        sortDesc: state.SORT.desc
      }
    },
    getLoadingState: (state) => state.LOADING,
    getCurrentPage: (state) => state.CONFIG.pagination.CURRENT_PAGE,
    getDirectusBaseURL: (state) => state.CONFIG.base_url,
    getQueryString: (state) => (id=null, filter=true, search=true, sort=true, pagination=true) => {
      var query_url = state.CONFIG.base_url
      var query_parts = []

      // id
      if(id)
        query_url += "/"+ id
      
      query_url += "?"
      

      // mapped fields
      if (!(Object.keys(state.CONFIG.field_mappings).length === 0) && state.CONFIG.field_mappings.constructor === Object){
        var mapped_fields =  []
        for (let field in state.CONFIG.field_mappings) {
          mapped_fields.push(state.CONFIG.field_mappings[field].field_name)
        }
        query_parts.push("fields=" + mapped_fields.join(","))
      }
      
      // filter
      // &filter={"mesh_terms":{"mesh_terms_uid":{"uid":{"_eq":"D014611"}}}}
      // filter[title][_contains]=SARS

    //   if (!(Object.keys(state.FILTER._and).length === 0)){
    //     var filter_strings = []
    //     for (let and_filter in state.FILTER._and) {
    //       var f = "filter="
    //       f += JSON.stringify(state.FILTER._and[and_filter])
    //       filter_strings.push(f)
    //     }
    //     query_parts.push(filter_strings.join("&"))
    //   }
    // }

      // search
      if (search) {
        if (state.SEARCH.length > 0) {
          var search_string = "search=" + state.SEARCH
          query_parts.push(search_string)
        }
      }


      // sort
      if (sort) {
        if (!(Object.keys(state.SORT.columns).length === 0)){
          var sort_string = "sort="
          if (state.SORT.desc[0] === true) {
            sort_string += "-"
          }
          sort_string += state.SORT.columns[0]
          query_parts.push(sort_string)
        }
      }


      query_url += query_parts.join("&")

      if (filter) {
        if (!(Object.keys(state.FILTER).length === 0)){
          // var f = "filter"
          // f += 
          query_url += state.FILTER
        }
      }

      // pagination
      if (pagination) {
        query_url += "&meta=filter_count&limit="+ state.CONFIG.pagination.ITEMS_PER_PAGE + "&page="+ state.CONFIG.pagination.CURRENT_PAGE
      }
      // console.log(query_url)

      return query_url
    }
  }
})
