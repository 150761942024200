<template>
    <div>
        <v-card class="my-5 mx-auto" v-if="paper != null">
            <v-card-actions>

                <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-chip outlined v-bind="attrs" v-on="on">
                    <v-rating
                        empty-icon="mdi-star-outline"
                        full-icon="mdi-star"
                        length="3"
                        size="30"
                        :value="parseInt(paper.rating_count)"
                        readonly
                        small
                    ></v-rating>
                    </v-chip>
                </template>
                <span v-if="paper.rating_count == 1">
                    Publikation mit interessanter, unbestätigter Hypothese oder mit unklarer Qualität
                </span>
                <span v-if="paper.rating_count == 2"
                    >Publikation für den Hintergrund und <br />Darstellung begründeter
                    Hypothesen</span
                >
                <span v-if="paper.rating_count == 3"
                    >Hoch relevante wissenschaftliche <br />Publikationen mit wichtigen
                    Befunden</span
                >
                </v-tooltip>
                <!-- <v-rating
                    empty-icon="mdi-star-outline"
                    full-icon="mdi-star"
                    length="3"
                    size="30"
                    :value="parseInt(paper.rating_count)"
                    readonly
                    small
                ></v-rating> -->
                <!-- PREPRINT -->
                <v-chip
                color="error"
                v-if="paper.preprint"
                class="mx-2"
                >Preprint</v-chip>
                <v-chip
                color="success"
                v-if="!paper.preprint"
                class="mx-2"
                >Peer-Reviewed</v-chip>
                <v-spacer/>
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon
                            v-clipboard:copy="`${getBaseURL()}/paper/${paper.id}`"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi-share-variant</v-icon>
                        </v-btn>
                    </template>
                    <span>Link zur Publikation kopieren</span>
                </v-tooltip>
            
                <v-snackbar
                    v-model="copied"
                    :timeout="3000"
                    >
                    Der Link der Publikation wurde in Ihre Zwischenablage kopiert.
                </v-snackbar>
            </v-card-actions>
            <v-card-title primary-title>

                <!-- AUTOREN KÜRZEN WENN MEHR ALS 1 -->
                <span v-if="paper.authors.split(',').length > 1" class="keep-spaces">{{ paper.authors.split(',')[0] }} et al. </span>
                <span v-else class="keep-spaces">{{ paper.authors }} </span>

                <span class="keep-spaces">({{ toGermanDate(paper.date)}}): </span>
                
                <!-- PUNKT HINZUFÜGEN, WENN TITEL NICHT MIT PUNKT ENDET -->
                <span v-if="paper.title.slice(-1) != '.'" class="keep-spaces">{{ paper.title }}. </span>
                <span v-else class="keep-spaces">{{ paper.title }} </span>

                <span class="keep-spaces">{{ paper.journal }}. </span>
                <span>
                    <a :href="paper.link_to_study" target="_blank">
                        <v-icon color="primary">mdi-link-variant</v-icon>
                    </a>
                </span>
            </v-card-title>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="4" v-if="paper.land_der_autoren_tf">
                        <v-card 
                            outlined
                            tile 
                            height="100%" 
                            class="d-flex flex-column">
                            <v-card-title class="text--secondary">
                                Land der Autoren
                            </v-card-title>
                            <v-card-text class="text--primary">
                                {{ paper.land_der_autoren_tf }}                                
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="paper.land_der_studie.length || paper.zeitraum_der_studie_von || paper.zeitraum_der_studie_bis">
                        <v-card
                            outlined
                            tile 
                            height="100%" 
                            class="d-flex flex-column">
                            <v-card-title class="text--secondary">
                                Land und Zeitraum der Analyse
                            </v-card-title>
                            <v-card-text class="text--primary">
                                <span v-if="paper.land_der_studie.length" class="keep-spaces">{{ paper.land_der_studie.join(", ") }} </span>
                                <span v-if="paper.zeitraum_der_studie_von" class="keep-spaces">ab {{ toGermanDate(paper.zeitraum_der_studie_von) }} </span>                          
                                <span v-if="paper.zeitraum_der_studie_bis" class="keep-spaces">bis {{ toGermanDate(paper.zeitraum_der_studie_bis) }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="paper.art_der_studie">
                        <v-card 
                            outlined
                            tile 
                            height="100%" 
                            class="d-flex flex-column">
                            <v-card-title class="text--secondary">
                                Art der Studie
                            </v-card-title>
                            <v-card-text class="text--primary">
                                <v-chip
                                color="secondary"
                                v-for="art in paper.art_der_studie"
                                class="mx-1 my-1"
                                :key="art.key"
                                outlined
                                >
                                {{ art.value }}
                                </v-chip>                     
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="paper.link_to_peer_reviewed_study">
                        <v-card 
                            outlined
                            tile 
                            height="100%" 
                            class="d-flex flex-column">
                            <v-card-title class="text--secondary">
                                Link
                            </v-card-title>
                            <v-card-text class="text--primary">
                                <a :href="paper.link_to_peer_reviewed_study" target="_blank">{{paper.link_to_peer_reviewed_study}}</a>
                                                       
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="paper.erschienen_in_fachjournal">
                        <v-card 
                            outlined
                            tile 
                            height="100%" 
                            class="d-flex flex-column">
                            <v-card-title class="text--secondary">
                                Art der Studie
                            </v-card-title>
                            <v-card-text class="text--primary">
                                {{ paper.erschienen_in_fachjournal }}         
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="paper.mesh_terms.length">
                        <v-card outlined tile>
                            <v-card-title class="text--secondary">
                                Schlagworte:
                            </v-card-title>
                            <v-card-text class="text--primary">

                                <v-chip
                                color="secondary"
                                v-for="term in paper.mesh_terms"
                                class="mx-1 my-1"
                                :key="term.key"
                                outlined
                                >
                                {{ term.value }}
                                </v-chip>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-if="paper.was_wurde_untersucht">
                        <v-card outlined tile>
                            <v-card-title class="text--secondary">
                                Was wurde untersucht?
                            </v-card-title>
                            <v-card-text class="text--primary" v-html="replaceLinks(paper.was_wurde_untersucht)">
                                {{ replaceLinks(paper.was_wurde_untersucht) }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-if="paper.ergebnisse">
                        <v-card outlined tile>
                            <v-card-title class="text--secondary">
                                Ergebnisse:
                            </v-card-title>
                            <v-card-text class="text--primary" v-html="replaceLinks(paper.ergebnisse)">
                                {{ replaceLinks(paper.ergebnisse) }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-if="paper.kommentar">
                        <v-card outlined tile>
                            <v-card-title class="text--secondary">
                                Kommentar:
                            </v-card-title>
                            <v-card-text class="text--primary"  v-html="replaceLinks(paper.kommentar)">
                                {{ replaceLinks(paper.kommentar) }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<style>
    .keep-spaces {
        white-space : pre-wrap;
    }
</style>
<script>
import { mapActions } from "vuex";

export default {
    name: "Paper",
    props: ['paper_prop'],
    methods: {
        ...mapActions(["getPaperByID"]),
        getBaseURL() {
            return window.location.origin;
        },
        onCopy: function () {
            this.copied = true
            // alert('You just copie5d: ' + e.text)
        },
        onError: function () {
            alert('Failed to copy texts')
        },
        toGermanDate(date) {
            return new Date(date).toLocaleDateString(undefined, {day: "2-digit", month: "2-digit", year: "numeric"})
        },
        replaceLinks(text) {
            //eslint-disable-next-line
            return text.replace(/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm, match => `<a href="${match}" target="_blank">${match}</a>`)
        }
    },

    data: () => ({
        paper: null,
        copied: false
    }),
    async created() {
        if (this.$route.params.id){
            await this.getPaperByID(this.$route.params.id).then((e)=>{
                this.paper = e;
            });   
        } if (this.paper_prop) {
            this.paper = this.paper_prop;
        }
    }

}

</script>
