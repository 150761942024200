<template>
  <v-footer padless>
    <v-col class="text-center" cols="12">
      <p>{{ new Date().getFullYear() }} | Science Media Center Germany gGmbH</p>
      <p>
        <a href="/impressum/" target="_blank">Impressum</a> |
        <a href="/datenschutz/" target="_blank">Datenschutz</a> |
        <a
          :href="
            'mailto:' + getFeedbackMail + '?subject=Feedback für ' + getAppName
          "
          >Feedback</a
        >
      </p>
      <div v-if="!getRssUrl === ''">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              color="primary"
              :href="getRssUrl"
              target="_blank"
              v-bind="attrs"
              v-on="on"
              icon
              outlined
            >
              <v-icon>mdi-rss</v-icon>
            </v-btn>
          </template>
          <span>RSS-Feed abonnieren</span>
        </v-tooltip>
      </div>
    </v-col>
  </v-footer>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapGetters(["getRssUrl", "getFeedbackMail", "getAppName"]),
  },
};
</script>