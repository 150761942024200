<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel v-model="panel">
      <v-expansion-panel-header>
        <v-card-title primary-title>
          <v-icon class="mr-3">mdi-information-outline</v-icon>
          Über diese Publikationsliste
        </v-card-title>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p>
          Diese durchsuchbare Datenbank mit von der Redaktion des SMC kommentierten wissenschaftlichen Publikationen zum
          Thema SARS-CoV-2/COVID-19 hatte den Zweck, im Zeitraum von Februar 2020 bis Dezember 2022 Medienschaffende
          dabei zu unterstützen, aktuelle relevante Publikationen schnell zu finden oder bereits veröffentlichte und
          bewertete Studien nach Themen zu filtern. Hierbei bitten wir zu beachten, dass es sich bei den Publikationen
          (lediglich) um eine Auswahl aller publizierten Forschungsdaten zu SARS-CoV-2/COVID-19 handelt. Die jeweils
          neuesten Publikationen befinden sich direkt auf der Startseite.
        </p>
        <p>
          Aufgrund der nachgelassenen Relevanz von COVID-19/SARS-CoV-2 in der öffentlichen Debatte wurde das
          fortlaufende Aktualisieren der Liste eingestellt. Sie dient nun noch als Publikationsdatenbank für den
          Zeitraum von Februar 2020 bis Dezember 2022.
        </p>
        <p>
          Alle Studien sind von der Redaktion bewertet worden und nach Relevanz mit ein bis drei Sternen versehen (ein
          Stern niedrig, drei Sterne hoch). Noch nicht von Fachkollegen begutachtete Preprint-Publikationen sind rot
          markiert und sollten mit Vorbehalt gelesen und interpretiert werden. Einige dieser Studien wurden mittlerweile
          in einem Peer-Review-Verfahren geprüft und in einem Fachjournal veröffentlicht.
        </p>
        <p>
          Bei Fragen, Anmerkungen oder Verbesserungsvorschlägen, wenden Sie sich bitte an <a href="mailto:redaktion@sciencemediacenter.de">redaktion@sciencemediacenter.de</a>.
        </p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  data() {
    return {
      panel: 0,
    }
  },
}
</script>
