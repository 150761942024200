<template>
  <v-card outlined class="mx-5 my-5">
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            fab
            x-small
            elevation="1"
            @click="close"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Filter löschen</span>
      </v-tooltip>
    </v-card-actions>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4">
          <v-autocomplete
            :items="filters"
            v-model="selectedFilter"
            item-text="label"
            return-object
            label="Filtern nach..."
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            :items="operations"
            v-model="selectedOperation"
            item-text="DisplayName"
            item-value="Directus"
            return-object
            label="Operation..."
            :disabled="operations.length <= 1"
            @input="search(value)"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <div
            v-if="
              selectedFilter.type == 'Date' &&
              selectedOperation.Directus != '_between'
            "
          >
            <v-menu
              ref="dateFrom"
              v-model="dateFrom"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="value['value']"
                  label="Datum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @input="search"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="value['value']"
                :active-picker.sync="activePicker"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
                @input="search"
              ></v-date-picker>
            </v-menu>
          </div>
          <!-- <div v-if="selectedFilter.type == 'Date' && selectedOperation.Directus == '_between'">
                        <v-menu
                        ref="dateFrom"
                        v-model="dateFrom"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="value['value']['from']"
                                label="von"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @input="search"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="value['value']['from']"
                                :active-picker.sync="activePicker"
                                :max="new Date().toISOString().substr(0, 10)"
                                min="1950-01-01"
                                @input="search"
                            ></v-date-picker>
                        </v-menu>
                        <v-menu
                        ref="dateTo"
                        v-model="dateTo"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="value['value']['to']"
                                label="bis"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @input="search"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="value['value']['to']"
                                :active-picker.sync="activePicker"
                                :max="new Date().toISOString().substr(0, 10)"
                                min="1950-01-01"
                                @input="search"
                            ></v-date-picker>
                        </v-menu>
                    </div> -->
          <!-- {{selectedFilter.options}} -->
          <div v-if="selectedFilter.type == 'List'">
            <!-- <v-autocomplete
              :items="selectedFilter.options"
              item-value="key"
              item-text="value"
              label="Wert"
              @input="search"
              multiple
              chips
              v-if="selectedFilter.multiple"
            /> -->
            <v-autocomplete
              :items="selectedFilter.options"
              v-model="value"
              item-value="key"
              item-text="value"
              return-object
              label="Wert"
              @input="search"
            />
          </div>
          <div v-if="selectedFilter.type == 'Boolean'">
            <v-checkbox
              :v-model="value['value']"
              :label="`Ist ${value['value'] ? '' : 'nicht'} ${
                selectedFilter.label
              }`"
              @change="search"
            ></v-checkbox>
          </div>
          <div v-if="selectedFilter.type == 'Integer'">
            <v-select
              :items="listFromInt(selectedFilter.max_value)"
              v-model="value['value']"
              :label="selectedFilter.label"
              @change="search"

              v-if="selectedFilter.max_value"
            />
            <v-text-field
              label="Nummer"
              v-model="value['value']"
              @input="debounceSearch"
              v-else
            ></v-text-field>
          </div>
          <div v-if="selectedFilter.type == 'String'">
            <v-text-field
              label="Suchterm"
              v-model="value['value']"
              @input="debounceSearch"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <!-- {{value}} -->
    </v-card-text>
  </v-card>
</template>
<script>
import _ from "lodash";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "singleFilter",
  computed: {
    ...mapGetters(["getConfig"]),
    ...mapMutations(["setCurrentPage", "setItemsPerPage", "setFilter"]),
  },
  watch: {
    selectedFilter: function (val) {
      this.operations = [];
      this.selectedOperation = {};
      this.value.value = "";
      this.value.key = "";

      val.filter.forEach((element) => {
        if (element in this.getConfig.filter_defs) {
          this.operations.push({
            DisplayName: this.getConfig.filter_defs[element],
            Directus: element,
          });
        }
      });
      this.selectedOperation = this.operations[0];

      if (val.type == "Boolean") {
        this.value.value = false;
      }

      this.search(this.value);
    },
  },
  methods: {
    close() {
      this.value.key = "";
      this.value.value = "";

      this.$emit("updated", true);
      this.$el.parentNode.removeChild(this.$el);
      this.$destroy();
    },
    queryJSON() {
      if (
        this.value.value === "" ||
        Object.keys(this.selectedOperation).length === 0 ||
        Object.keys(this.selectedFilter).length === 0
      )
        return "";
      if (!this.value.key)
        return `&filter${this.selectedFilter.filter_path}[${this.selectedOperation.Directus}]=${this.value.value}`;

      return `&filter${this.selectedFilter.filter_path}[${this.selectedOperation.Directus}]=${this.value.key}`;
      // return { [this.selectedFilter.filter_path]: {[this.selectedOperation.Directus]: this.value.key }}
    },
    search(e) {
      if (e instanceof Object && "value" in e && "key" in e) this.value = e;
      else this.value["value"] = e;

      this.$emit("updated", true);

      // this.$store.commit("setFilter", this.queryJSON());
    },
    debounceSearch(e) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search(e);
      }, 600);
    },
    listFromInt(i) {
      return _.range(1, i+1)
    }
  },
  created() {
    for (var mapping of Object.values(this.getConfig.field_mappings)) {
      if (mapping.filter.length) {
        this.filters.push(mapping);
      }
    }
  },
  data: () => ({
    debounce: null,

    // DATEPICKER
    activePicker: null,
    dateFrom: false,
    dateTo: false,

    // VARIABLEN
    selectedFilter: {},
    filters: [],

    selectedOperation: {},
    operations: [],

    value: { key: "", value: "" },
  }),
};
</script>