<template>
  <div id="filters">
    <singleFilter
      v-for="(component, index) in components"
      :key="index"
      :is="component"
      ref="filter"
      @updated="getQueriesFromComponents"
    />
    <!-- <component
        v-for="(component, index) in components"
        :key="index"
        :is="component"
        :ref="`filter-${index}`"

        @updated="getQueriesFromComponents"
        /> -->
    <!-- <singleFilter /> -->
    <v-row justify="center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            fab
            small
            v-bind="attrs"
            v-on="on"
            @click="add"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Filter hinzufügen</span>
      </v-tooltip>
    </v-row>

    <!-- <v-btn
                color="primary"
                fab
                small
                @click="getQueriesFromComponents"
            >test
            </v-btn> -->
  </div>
</template>
<script>
import singleFilter from "./_singleFilter.vue";
// import filterTypes from "../../public/config/filter-types.json";

export default {
  name: "Filters",
  components: {
    singleFilter,
  },
  data() {
    return {
      components: [singleFilter],
    };
  },
  methods: {
    add() {
      this.components.push(singleFilter);
    },
    closeFilter(e) {
      this.$destroy(this.components, e);
    },
    getQueriesFromComponents() {
      var q = "";
      this.$refs.filter.forEach((e) => {
        var querystring = e.queryJSON();
        if (querystring !== "") q += querystring;
      });
      this.$store.commit("setFilter", q);
    },
  },
};
</script>
