<template>
  <div class="documents">
      <v-card class="py-3">
        <v-pagination
        v-model="CurrentPage"
        :length="Math.ceil(Pagination.totalItems/Pagination.itemsPerPage)"
        circle
        ></v-pagination>
    </v-card>
    <Paper v-for="doc in getAllDocuments" :key="doc.id" :paper_prop="doc"/>
    <v-card class="py-3">
        <v-pagination
        v-model="CurrentPage"
        :length="Math.ceil(Pagination.totalItems/Pagination.itemsPerPage)"
        circle
        ></v-pagination>
        <v-col class="col-2 mx-auto">
        Publikationen pro Seite:
        <v-select
            v-model="itemsPerPage"
            :items="[25, 50, 75, 100]"
            hide-details
            single-line
            dense
        ></v-select>
        </v-col>
    </v-card>
  </div>
</template>
<style>

.doc tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import { mapGetters, mapMutations } from "vuex";

import Paper from "@/views/Paper.vue";


export default {
    name: "myTable",
    components: {
        Paper
    },
    methods: {
        handleClick(e) {
        window.open("/paper/"+e.id, '_blank');
        }
    },
    computed: {
        ...mapGetters(["getAllDocuments", "getItemsPerPage", "getTotalItems", "getCurrentPage", "getPagination"]),
        ...mapMutations(["setCurrentPage", "setItemsPerPage", "setSort", "setSearch"]),

        CurrentPage: {
            get () {
                return this.getCurrentPage
            },
            set (value) {
                this.$store.commit('setCurrentPage', value);
                this.$vuetify.goTo(0)
            }
        },
        itemsPerPage: {
            get () {
                return this.getItemsPerPage
            },
            set (value) {
                this.$store.commit('setItemsPerPage', value);
            }
        },
        Pagination: {
        get () {
            return this.getPagination
        },
        set(value) {
            this.$store.commit('setCurrentPage', value.page);
            this.$store.commit('setItemsPerPage', value.itemsPerPage);
            var q = { "columns": value.sortBy, "desc": value.sortDesc };
            this.$store.commit('setSort', q);
        }
        },
        
        total_pages () {
            if (this.getTotalItems == null || this.getItemsPerPage == null) return 0

            return Math.ceil(this.getTotalItems / this.getItemsPerPage)
        }
    },
    data: () => ({
        search: "",        
        loading: true,
        headers: [
        { text: 'Ranking', value: 'rating_count' },
        { text: 'Preprint', value: 'preprint' },
        { text: 'Datum', value: 'date' },
        { text: 'Titel', value: 'title' },
        { text: 'Journal', value: 'journal' },
        { text: 'Land', value: 'land_der_studie' },
        { text: '', value: 'link_to_study' },
        ],
    }),

};
</script>
