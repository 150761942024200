import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
export default new Vuetify({

  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
          primary: '#D7C455', //check
          secondary: '#787878',
          accent: '#b0c4e8',
          error: '#D9796A', //check
          info: '#82A0D7',
          success: '#9EB5A6', //check
          warning: '#EDA85F',
      },
    },
  },
  })

