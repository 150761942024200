<template>
  <div class="documents">
    <!--         :loading="loading" -->
    <v-data-table
      :headers="headers"
      :items="getAllDocuments"
      :options.sync="Pagination"
      :server-items-length="Pagination.totalItems"
      :footer-props="{
        'items-per-page-options': [25, 50, 75, 100],
      }"
      @click:row="handleClick"
      class="elevation-1 doc"
    >
      <template v-slot:[`item.rating_count`]="{ item }">
        <!-- RATING AS STARS -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip outlined v-bind="attrs" v-on="on">
              <v-rating
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                length="3"
                size="30"
                :value="parseInt(item.rating_count)"
                readonly
                small
              ></v-rating>
            </v-chip>
          </template>
          <span v-if="item.rating_count == 1">
            Publikation mit interessanter, unbestätigter Hypothese oder mit unklarer Qualität
          </span>
          <span v-if="item.rating_count == 2"
            >Publikation für den Hintergrund und <br />Darstellung begründeter
            Hypothesen</span
          >
          <span v-if="item.rating_count == 3"
            >Hoch relevante wissenschaftliche <br />Publikationen mit wichtigen
            Befunden</span
          >
        </v-tooltip>
      </template>
      <template v-slot:[`item.preprint`]="{ item }">
        <!-- RATING AS STARS -->
        <!-- <v-avatar v-if="item.preprint" color="error" size="25"><v-icon>mdi-check</v-icon></v-avatar> -->
        <span v-if="item.preprint" class="error--text">Preprint</span>

        <!-- <v-avatar v-if="!item.preprint" color="success" size="25"><v-icon>mdi-window-close</v-icon></v-avatar> -->
        <span v-if="!item.preprint" class="success--text">Peer-Reviewed</span>
      </template>
      <!-- JOURNAL NAME -->
      <!-- <template v-slot:[`item.journal`]="{ item }">
          <span v-if="item.journal !=null">{{ item.journal.name }}</span>
        </template> -->
      <!-- AUTHORS JUST THREE -->
      <!-- <template v-slot:[`item.authors`]="{ item }">
          <span v-for="(author, i) in item.authors.split(', ').slice(0, 4)" :key="i">
            <span v-if="i < item.authors.split(', ').length-1 && i < 2">{{ author }}, </span>
            <span v-if="i == item.authors.split(', ').length-1 || i == 2">{{ author }}</span>
            <span v-if="i === 3"> et al.</span>
          </span>
        </template> -->
      <!-- DATE FORMAT -->
      <template v-slot:[`item.date`]="{ item }">
        {{
          new Date(item.date).toLocaleDateString(undefined, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        }}
      </template>
      <!-- CUT TITLE -->
      <!-- <template v-slot:[`item.title`]="{ item }">
          <span v-if="item.title.length > 100">{{ item.title.substr(0, 100) + "\u2026" }}</span>
          <span v-else>{{ item.title }}</span>
        </template> -->


      <template v-slot:[`item.land_der_studie`]="{ item }">
        {{ item.land_der_studie.join(", ") }}
      </template>
      <!-- LINK ICON -->

      <template v-slot:[`item.link_to_study`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <a
              :href="item.link_to_study"
              target="_blank"
              rel="noopener noreferrer"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-link-variant</v-icon>
            </a>
          </template>
          <span>Details zur Publikation anzeigen.</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- <div class="text-xs-center pt-2"> -->
    <!-- <v-pagination v-model="CurrentPage" :length="total_pages" circle></v-pagination> -->
    <!-- </div> -->
  </div>
</template>
<style>
.doc tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "myTable",
  methods: {
    handleClick(e) {
      window.open("/paper/" + e.id, "_blank");
    },
  },
  computed: {
    ...mapGetters([
      "getAllDocuments",
      "getItemsPerPage",
      "getTotalItems",
      "getCurrentPage",
      "getPagination",
    ]),
    ...mapMutations([
      "setCurrentPage",
      "setItemsPerPage",
      "setSort",
      "setSearch",
    ]),

    CurrentPage: {
      get() {
        return this.getCurrentPage;
      },
      set(value) {
        this.$store.commit("setCurrentPage", value);
      },
    },
    Pagination: {
      get() {
        return this.getPagination;
      },
      set(value) {
        this.$store.commit("setCurrentPage", value.page);
        this.$store.commit("setItemsPerPage", value.itemsPerPage);
        var q = { columns: value.sortBy, desc: value.sortDesc };
        this.$store.commit("setSort", q);
      },
    },

    total_pages() {
      if (this.getTotalItems == null || this.getItemsPerPage == null) return 0;

      return Math.ceil(this.getTotalItems / this.getItemsPerPage);
    },
  },

  data: () => ({
    loading: true,
    headers: [
      { text: "Rating", value: "rating_count" },
      { text: "Status", value: "preprint" },
      { text: "Datum", value: "date" },
      { text: "Titel", value: "title" },
      { text: "Journal", value: "journal" },
      { text: "Land", value: "land_der_studie" },
      { text: "", value: "link_to_study" },
    ],
  }),
};
</script>
