import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Paper from '../views/Paper.vue'
import Impressum from '../views/Impressum.vue'
import Datenschutz from '../views/Datenschutz.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/paper/:id',
    name: 'Paper',
    component: Paper
  },
  {
    path: '/impressum/',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/datenschutz/',
    name: 'Datenschutz',
    component: Datenschutz
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
