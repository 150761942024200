import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import underscore from 'vue-underscore';
import VueClipboard from 'vue-clipboard2';
import VueMatomo from 'vue-matomo';


Vue.config.productionTip = false
Vue.use(underscore);
Vue.use(VueClipboard)

Vue.use(VueMatomo, {
  host: "https://seo.villa-bosch.de/piwik",
  siteId: 17,
  trackerFileName: 'matomo',
  router: router,
  setDoNotTrack: true,
  disableCookies: true,
  trackPageView: true,
  enableLinkTracking: true,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
