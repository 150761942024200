<template>
  <div>
    <Erklaerbaer/>
    <v-card class="mt-5 py-5">
      <v-card-title primary-title> Filter </v-card-title>
      <myFilters />
    </v-card>

    <v-card class="my-5">
      <v-card-title primary-title>
        <span>Publikationen</span>
        <v-spacer />

        <v-btn-toggle v-model="tableView" mandatory>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :value="true" v-bind="attrs" v-on="on">
                <v-icon>mdi-view-list</v-icon>
              </v-btn>
            </template>
            <span>Listenansichten</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :value="false" v-bind="attrs" v-on="on">
                <v-icon>mdi-apps</v-icon>
              </v-btn>
            </template>
            <span>Kachelansichten</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-card-title>

      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Volltext-Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-card-title v-if="getLoadingState">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-title>

      <myTable v-if="tableView" />
    </v-card>
    <div v-if="!tableView">
      <myGrid />
    </div>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      fixed
      bottom
      right
      color="primary"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </div>
</template>



<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

import Erklaerbaer from "../components/Layout/Erklaerbaer.vue";
import myFilters from "../components/Filters.vue";
import myTable from "../components/Table.vue";
import myGrid from "../components/Grid.vue";

export default {
  name: "Home",

  components: {
    myTable,
    myGrid,
    myFilters,
    Erklaerbaer
  },
  computed: {
    ...mapGetters(["getLoadingState"]),
  },
  data() {
    return {
      search: "",
      tableView: false,
      fab: false

    };
  },
  methods: {
    ...mapActions(["fetchOptions", "fetchDocuments"]),
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  },
  watch: {
    search: _.debounce(function (e) {
      this.$store.commit("setSearch", e);
    }, 400),

  },
  async created() {
    await this.fetchDocuments();
    await this.fetchOptions();
  },
};
</script>
