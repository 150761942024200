<template>
  <v-app>
    <v-app-bar
      app
      dark
    >
    <a href="/">
      <div class="d-flex align-center" transition="scale-transition">
        <v-img
          alt="Science Media Center"
          class="shrink mr-2"
          contain
          src="./assets/smc_logo.png"
          width="80"
        />
        <v-toolbar-title class="white--text">{{getAppName}}</v-toolbar-title>

        <!-- <h1 class="white--text"></h1> -->
      </div>
    </a>

    </v-app-bar>

    <v-main>
      <v-container>
        <v-row>
          <v-col
            cols="12"
          >
            <v-sheet
              min-height="70vh"
              rounded="lg"
              color="transparent"
              max-width="90rem"
              class="mx-auto"
            >
              <router-view/>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <customFooter/>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import customFooter from "./components/Layout/Footer.vue";

export default {
  name: 'App',
  computed: {
    ...mapGetters(["getAppName"]),
  },
   
  data: () => ({
    //
  }),
  components: {
    customFooter,
  },
  created() {
    document.title = this.getAppName;
  }
};
</script>
<style type="text/scss">
@font-face {
    font-family: "Aller";
    src: local("Aller"),
    url('./assets/font/Aller_Rg.ttf') format('truetype');
}
* {
    font-family: "Aller", 'Roboto', sans-serif !important;
}
.v-application {
    background-image: url('./assets/background.jpg') !important;
    background-position: center !important;
    background-size: cover !important;
    background-attachment: fixed !important;
}
header.v-app-bar{
    background-image: url('./assets/nav_background.svg') !important;
    background-position: center !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    }
</style>
